import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DashboardNavigationSkeleton from "./dashboard-navigation-skeleton";

function TeamsSkeleton() {
  return (
    <SkeletonTheme>
      <DashboardNavigationSkeleton />
      <div className="flex md:flex-row flex-col gap-5 px-4 justify-between">
        <Skeleton borderRadius={21} width={50} height={50} />

        <div className="md:w-2/3 w-full">
          <Skeleton borderRadius={21} className="w-full" height={200} />
        </div>

        <div className="md:w-2/3 w-full">
          <Skeleton borderRadius={21} className="w-full" height={200} />
        </div>

        <div className="md:w-2/3 w-full">
          <Skeleton borderRadius={21} className="w-full" height={200} />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default TeamsSkeleton;
