import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function AddEditPostSkeleton() {
  return (
    <SkeletonTheme>
      <div className="bg-white w-full h-full">
        <div className="flex justify-between items-center px-5 py-3">
          <div className="flex justify-between items-center gap-3">
            <Skeleton borderRadius={50} width={100} height={20} />
            <Skeleton borderRadius={50} width={100} height={20} />
            <Skeleton borderRadius={50} width={100} height={20} />
            <Skeleton borderRadius={50} width={100} height={20} />
          </div>
          <div className="flex justify-between items-center gap-3">
            <Skeleton borderRadius={50} width={140} height={40} />
            <Skeleton borderRadius={50} width={140} height={40} />
          </div>
        </div>
        <div className="flex justify-between h-5/6 items-center gap-4 px-4">
          <div className="w-full h-full">
            <Skeleton borderRadius={21} className="w-full h-full" />
          </div>
          <div className="w-1/3 h-full">
            <Skeleton borderRadius={21} className="w-full h-full" />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default AddEditPostSkeleton;
