import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Card = () => {
  return (
    <div className="md:w-72 w-full justify-center p-4 h-36 border-2 rounded-[21px] flex flex-col">
      <Skeleton width={200} height={18} count={1} />
      <div className="flex gap-2">
        <Skeleton
          circle={true}
          inline={true}
          width={10}
          height={10}
          count={1}
        />
        <Skeleton width={100} height={10} count={1} />
      </div>
      <div className="flex gap-2 mt-3">
        <Skeleton width={80} inline={true} height={30} count={1} />
        <Skeleton width={80} height={30} count={1} />
      </div>
    </div>
  );
};
function ProjectCardSkeleton() {
  return (
    <SkeletonTheme>
      <div className="flex justify-start flex-wrap gap-6 mt-4 p-5">
        {/* card */}
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </SkeletonTheme>
  );
}

export default ProjectCardSkeleton;
