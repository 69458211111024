import { useState, useEffect } from "react";

const useEventEmitter = () => {
  const [eventManager] = useState({
    events: {},
    subscribe(eventName, callback) {
      if (!this.events[eventName]) {
        this.events[eventName] = [];
      }
      this.events[eventName].push(callback);

      // Return a function that can be used to unsubscribe
      return () => {
        this.unsubscribe(eventName, callback);
      };
    },
    unsubscribe(eventName, callback) {
      const callbacks = this.events[eventName];
      if (callbacks) {
        const index = callbacks.indexOf(callback);
        if (index !== -1) {
          callbacks.splice(index, 1);
        }
      }
    },
    emit(eventName, data) {
      const callbacks = this.events[eventName];
      if (callbacks) {
        callbacks.forEach((callback) => {
          callback(data);
        });
      }
    },
  });

  useEffect(() => {
    // Clean up the event manager when the component unmounts
    return () => {
      for (const eventName in eventManager.events) {
        eventManager.events[eventName] = []; // Clear all event listeners
      }
    };
  }, []);

  return eventManager;
};

export { useEventEmitter };
