import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DashboardNavigationSkeleton from "./dashboard-navigation-skeleton";

function SettingsSkeleton() {
  return (
    <SkeletonTheme>
      <DashboardNavigationSkeleton />
      <div className="md:px-8 px-4">
        <div className="flex gap-5 mt-2 flex-wrap">
          <Skeleton borderRadius={21} width={60} height={20} />
          <Skeleton borderRadius={21} width={60} height={20} />
          <Skeleton borderRadius={21} width={60} height={20} />
          <Skeleton borderRadius={21} width={60} height={20} />
        </div>
        <div className="md:w-7/12 w-full mt-24 text-end">
          <Skeleton borderRadius={21} className="my-5 " height={40} />
          <Skeleton borderRadius={21} className="my-5 " height={40} />
          <Skeleton borderRadius={21} width={100} height={40} />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default SettingsSkeleton;
