import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NavigationSkeleton from "./navigation-skeleton";

function ProfileSkeleton() {
  return (
    <SkeletonTheme>
      <NavigationSkeleton />
      <div className="flex flex-col w-full items-center gap-10 mt-7 px-5">
        <div className="border-2 rounded-[21px] p-4 md:w-[450px] w-full md:h-[250px] h-auto">
          <div className="text-center">
            <Skeleton borderRadius={50} width={200} height={20} />
          </div>
          <div className="text-end mt-8">
            <Skeleton borderRadius={50} width={180} height={10} />
            <Skeleton borderRadius={50} width={200} height={10} />
            <Skeleton borderRadius={50} width={160} height={10} />
            <Skeleton borderRadius={50} width={220} height={10} />
            <Skeleton borderRadius={50} width={120} height={10} />
          </div>
        </div>
        <div className="border-2 rounded-[21px] p-4 md:w-[450px] w-full md:h-[240px] h-auto">
          <div className="text-center">
            <Skeleton borderRadius={50} width={200} height={20} />
          </div>
          <div className="p-4">
            <div className="w-full my-4">
              <Skeleton borderRadius={50} height={30} />
            </div>
            <div className="w-full my-4">
              <Skeleton borderRadius={50} height={30} />
            </div>
            <div className="w-full my-4">
              <Skeleton borderRadius={50} height={30} />
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default ProfileSkeleton;
