import React, { useContext, useState } from "react";
import "./index.scss";
import ModalInput from "../../ui-kit/modal";
import { Link, useParams } from "react-router-dom";

export const PaywallContext = React.createContext({});

export const PaywallProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [renderItems, setRenderItems] = useState({});

  const { projectID } = useParams();

  return (
    <PaywallContext.Provider
      value={{
        isVisible,
        setIsVisible,
        renderItems,
        setRenderItems,
      }}
    >
      <ModalInput
        isOpened={isVisible}
        onOutsideClick={() => {
          setIsVisible(false);
        }}
        overlayFrameClassName={"w-[500px] max-w-[80%]"}
      >
        <div className="p-8 text-center text-grigora-base">
          <img
            src="/images/access-denied.svg"
            alt="Limit"
            className="mb-5 w-[200px] mx-auto"
          />
          <h1 className="modal-heading mb-3">
            {renderItems?.title ?? "You have reached limit!"}
          </h1>
          <p className="mb-5">
            {renderItems?.description ??
              "Please upgrade your plan to continue."}
          </p>
          <div className="text-center">
            <Link
              to={renderItems?.link ?? `/billing`}
              onClick={(e) => {
                setIsVisible(false);
              }}
              className="grigora-action-btn"
            >
              Upgrade
            </Link>
          </div>
        </div>
      </ModalInput>
      {children}
    </PaywallContext.Provider>
  );
};
