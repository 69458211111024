import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DashboardNavigationSkeleton from "./dashboard-navigation-skeleton";

function AddCategorySkeleton() {
  return (
    <SkeletonTheme>
      <DashboardNavigationSkeleton />
      <div className="md:px-8 px-4 flex md:flex-row flex-col-reverse jusfity-between gap-2">
        <div className="md:w-7/12 w-full md:mt-14 mt-4">
          <Skeleton borderRadius={21} className="my-3 " height={30} />
          <Skeleton borderRadius={21} className="my-3 " height={30} />
          <Skeleton borderRadius={21} className="my-3 " height={170} />
          <Skeleton
            borderRadius={21}
            className="my-3"
            width={120}
            height={40}
          />
        </div>
        <div className="w-full md:w-2/5">
          <Skeleton borderRadius={21} className="w-full mt-10" height={200} />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default AddCategorySkeleton;
