import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DashboardNavigationSkeleton from "./dashboard-navigation-skeleton";

function CategorySkeleton() {
  return (
    <SkeletonTheme>
      <div className="p-4 w-full">
        <DashboardNavigationSkeleton hasSearchBar={true} />
        <div className="flex justify-between w-full items-center gap-2">
          <Skeleton borderRadius={21} width={160} height={50} />
        </div>
        <Skeleton
          borderRadius={21}
          className="mx-auto h-full mt-3 w-full"
          height={470}
        />
      </div>
    </SkeletonTheme>
  );
}

export default CategorySkeleton;
