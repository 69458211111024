import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NavigationSkeleton from "./navigation-skeleton";
import DashboardNavigationSkeleton from "./dashboard-navigation-skeleton";

function SupportSkeleton() {
  return (
    <SkeletonTheme>
      <DashboardNavigationSkeleton />
      <div className="md:px-8 px-4">
        <div className="flex gap-5 mt-10">
          <Skeleton borderRadius={21} width={70} height={20} />
          <Skeleton borderRadius={21} width={70} height={20} />
          <Skeleton borderRadius={21} width={70} height={20} />
        </div>
        <div className="md:w-7/12 w-full mt-10">
          <Skeleton borderRadius={21} className="my-3 " height={30} />
          <Skeleton borderRadius={21} className="my-3 " height={200} />
          <Skeleton
            borderRadius={21}
            className="my-3"
            width={150}
            height={40}
          />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default SupportSkeleton;
