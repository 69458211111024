import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { APIProviderContext } from "../api";
import { PulseLoader } from "react-spinners";
import { useSearchParams } from "react-router-dom";
import LIMITS from "../../pages/settings/limits";

const GrigoraAccountContext = createContext();

var enterpriseDomains = ["@grigora.co", "@latracal.com"];

const GrigoraAccount = (props) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { getUserData } = useContext(APIProviderContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaasZillaCampaignVisible, setIsSaasZillaCampaignVisible] =
    useState(false);

  useEffect(() => {
    setIsLoading(true);

    getUserData()
      .then((data) => {
        setUserData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!isLoading) {
    }
  }, [isLoading]);

  const isSubscribed = useMemo(() => {
    if (enterpriseDomains.some((domain) => userData?.email?.endsWith(domain))) {
      return true;
    }

    return userData?.subscription?.status === "active";
  }, [userData?.subscription?.status]);

  const subscriptionPlan = useMemo(() => {
    if (enterpriseDomains.some((domain) => userData?.email?.endsWith(domain))) {
      return "enterprise";
    }

    return userData?.subscription?.plan ?? "free";
  }, [userData?.subscription?.plan]);

  const subscriptionLimits = useMemo(() => {
    if (!isSubscribed) {
      return LIMITS.free;
    }

    return LIMITS[subscriptionPlan] ?? LIMITS.free;
  }, [isSubscribed, subscriptionPlan]);

  return (
    <GrigoraAccountContext.Provider
      value={{
        userData,
        setUserData,
        isSubscribed,
        subscriptionPlan,
        subscriptionLimits,
      }}
    >
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <PulseLoader color="black" size={6} />
        </div>
      ) : (
        props.children
      )}
    </GrigoraAccountContext.Provider>
  );
};
export { GrigoraAccount, GrigoraAccountContext };
