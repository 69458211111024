import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AccountContext } from "../account/account";
import { io } from "socket.io-client";
import useInterval from "use-interval";
import { isJsonString } from "../helpers";
import { useEventEmitter } from "../hooks";

function isWSOpen(ws) {
  return ws.readyState === ws.OPEN;
}

const RealtimeContext = createContext();

const RealtimeProvider = (props) => {
  const { userAttributes, currentUser, idToken, accessToken } =
    useContext(AccountContext);

  // Create a new realtime client socket
  const [socket, setSocket] = useState(null);

  const socketEvents = useEventEmitter();

  function connectWS() {
    let connectionURL = new URL(process.env.REACT_APP_GRIGORA_SOCKET_URL);

    connectionURL.searchParams.append("access_token", accessToken);

    const socket = new WebSocket(connectionURL);
    return socket;
  }

  const onMessage = useCallback(
    (event) => {
      if (!socket) {
        return;
      }

      if (!event.data) return;
      if (!isJsonString(event.data)) {
        return;
      }

      const data = JSON.parse(event.data);

      var type = data.type;

      socketEvents.emit(type, data);
    },
    [socket, socketEvents],
  );

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", onMessage);
    }

    return () => {
      if (socket) {
        socket.removeEventListener("message", onMessage);
      }
    };
  }, [socket]);

  // Initialize a new realtime client socket
  useEffect(() => {
    const socket = connectWS();
    setSocket(socket);

    return () => {
      socket.close();
    };
  }, [accessToken]);

  // Ping Pong to keep connection alive
  useInterval(() => {
    if (socket) {
      if (isWSOpen(socket)) {
        socket.send(JSON.stringify({ type: "ping" }));
      } else {
        // Reconnect socket if disconnected
        const socket = connectWS();
        setSocket(socket);
      }
    }
  }, 2 * 60 * 1000);

  // Reconnect socket if disconnected
  // useEffect(() => {
  //   if (socket) {
  //     socket.onclose = () => {
  //       console.log("Socket is closed. Reconnect will be attempted in 1 second.");
  //       setTimeout(() => {
  //         setSocket(new WebSocket(process.env.REACT_APP_GRIGORA_SOCKET_URL));
  //       }, 1000);
  //     };
  //   }
  // }, [socket]);

  return (
    <RealtimeContext.Provider
      value={{
        socket,
        socketEvents,
      }}
    >
      {props.children}
    </RealtimeContext.Provider>
  );
};
export { RealtimeContext, RealtimeProvider };
