import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DashboardNavigationSkeleton from "./dashboard-navigation-skeleton";

function UserDashboardSkeleton() {
  return (
    <SkeletonTheme>
      <DashboardNavigationSkeleton hasSearchBar={true} />
      <div className="flex justify-between w-full gap-4 p-4">
        <div className="w-full">
          <div className="flex md:flex-row flex-col items-center w-full  gap-4">
            <div className="w-full">
              <Skeleton borderRadius={21} className="w-full" height={120} />
            </div>

            <div className="w-full">
              <Skeleton borderRadius={21} className="w-full" height={120} />
            </div>
            <div className="w-full">
              <Skeleton borderRadius={21} className="w-full" height={120} />
            </div>
          </div>
          <div className="w-full mt-4 h-full md:block hidden">
            <Skeleton borderRadius={21} className="w-full h-full" />
          </div>
        </div>
        <div className="w-5/12 md:block hidden">
          <div className="w-full">
            <Skeleton borderRadius={21} className="w-full" height={150} />
          </div>

          <div className="w-full">
            <Skeleton borderRadius={21} className="w-full" height={200} />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default UserDashboardSkeleton;
