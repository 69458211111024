import { QueryClient, QueryClientProvider } from "react-query";
import { Routes, Route } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GrigoraVersioning } from "./components/versioning/context";
import React, { Suspense, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import LoginRequired from "./components/account/loginRequired";
import { Account } from "./components/account/account";
import { DashboardSkeleton } from "./components/dashboard/dashboardSkeleton";
import AuthSkeleton from "./components/skeletons/auth-skeleton";
import NotFoundSkeleton from "./components/skeletons/not-found-skeleton";
import ProjectSkeleton from "./pages/projects/projectSkeleton";
import ProfileSkeleton from "./components/skeletons/profile-skeleton";
import ProjectsSkeleton from "./components/skeletons/projects-skeleton";
import NewProjectSkeleton from "./components/skeletons/new-project-skeleton";
import StartNewProjectSkeleton from "./components/skeletons/start-new-project-skeleton";
import FilesSkeleton from "./components/skeletons/files-skeleton";
import SupportSkeleton from "./components/skeletons/support-skeleton";
import TeamsSkeleton from "./components/skeletons/teams-skeleton";
import TemplateSkeleton from "./components/skeletons/templates-skeleton";
import TodoSkeleton from "./components/skeletons/todo-skeleton";
import SettingsSkeleton from "./components/skeletons/settings-skeleton";
import CategorySkeleton from "./components/skeletons/category-skeleton";
import AddCategorySkeleton from "./components/skeletons/add-category";
import PostsSkeleton from "./components/skeletons/posts-skeleton";
import IntegrationSkeleton from "./components/skeletons/integration-skeleton";
import GrigoraPreviewSkeleton from "./components/skeletons/grigora-preview";
import AddEditPostSkeleton from "./components/skeletons/add-edit-post-skeleton";
import UserDashboardSkeleton from "./components/skeletons/user-dashboard-skeleton";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

// import { stopReportingRuntimeErrors } from "react-error-overlay";

// if (process.env.NODE_ENV === "development") {
//   stopReportingRuntimeErrors(); // disables error overlays
// }

// Convert all the above pages into lazy loaded components
const Login = React.lazy(() =>
  import(
    /* webpackChunkName: "page-login" */
    /* webpackMode: "lazy" */
    "./pages/login/login"
  ),
);
const Logout = React.lazy(() =>
  import(
    /* webpackChunkName: "page-logout" */
    /* webpackMode: "lazy" */
    "./pages/logout"
  ),
);
const NotFound404 = React.lazy(() =>
  import(
    /* webpackChunkName: "page-404" */
    /* webpackMode: "lazy" */
    "./pages/404/404"
  ),
);
const Profile = React.lazy(() =>
  import(
    /* webpackChunkName: "page-profile" */
    /* webpackMode: "lazy" */
    "./pages/Profile/profile"
  ),
);
const Billing = React.lazy(() =>
  import(
    /* webpackChunkName: "page-billing" */
    /* webpackMode: "lazy" */
    "./pages/Billing/billing"
  ),
);
const SaaszillaRedeem = React.lazy(() =>
  import(
    /* webpackChunkName: "page-saaszilla-redeem" */
    /* webpackMode: "lazy" */
    "./pages/saaszilla-redeem"
  ),
);
const Verify = React.lazy(() =>
  import(
    /* webpackChunkName: "page-verify" */
    /* webpackMode: "lazy" */
    "./pages/verify/verify"
  ),
);
const Register = React.lazy(() =>
  import(
    /* webpackChunkName: "page-register" */
    /* webpackMode: "lazy" */
    "./pages/register/register"
  ),
);

const Projects = React.lazy(() =>
  import(
    /* webpackChunkName: "page-projects" */
    /* webpackMode: "lazy" */
    "./pages/projects/projects"
  ),
);
const Oauth = React.lazy(() =>
  import(
    /* webpackChunkName: "page-oauth" */
    /* webpackMode: "lazy" */
    "./pages/oauth/oauth"
  ),
);
const VerifyCollaboratorInvitation = React.lazy(() =>
  import(
    /* webpackChunkName: "page-verify-collaborator-invitation" */
    /* webpackMode: "lazy" */
    "./pages/verify_collaborator_invitation"
  ),
);
const NewProject = React.lazy(() =>
  import(
    /* webpackChunkName: "page-new-project" */
    /* webpackMode: "lazy" */
    "./pages/new-project"
  ),
);
const StartNewProject = React.lazy(() =>
  import(
    /* webpackChunkName: "page-start-new" */
    /* webpackMode: "lazy" */
    "./pages/start-new"
  ),
);

// Lazy load forgot password
const Home = React.lazy(() =>
  import(
    /* webpackChunkName: "page-home" */
    /* webpackMode: "lazy" */
    "./pages/home/home"
  ),
);
// Lazy load forgot password
const ForgotPassword = React.lazy(() =>
  import(
    /* webpackChunkName: "page-forgotpassword" */
    /* webpackMode: "lazy" */
    "./pages/login/forgotPassword"
  ),
);
const Dashboard = React.lazy(() => import("./pages/dashboard/dashboard"));
const UserDashboard = React.lazy(() =>
  import("./pages/userdashboard/userdashboard"),
);
const Files = React.lazy(() =>
  import(
    /* webpackChunkName: "page-files" */
    /* webpackMode: "lazy" */
    "./pages/files/files"
  ),
);
const Support = React.lazy(() =>
  import(
    /* webpackChunkName: "page-support" */
    /* webpackMode: "lazy" */
    "./pages/support/support"
  ),
);
const Teams = React.lazy(() =>
  import(
    /* webpackChunkName: "page-teams" */
    /* webpackMode: "lazy" */
    "./pages/teams/index"
  ),
);
const Templates = React.lazy(() =>
  import(
    /* webpackChunkName: "page-templates" */
    /* webpackMode: "lazy" */
    "./pages/templates/index"
  ),
);
const Todo = React.lazy(() =>
  import(
    /* webpackChunkName: "page-todo" */
    /* webpackMode: "lazy" */
    "./pages/todo/todo"
  ),
);
const Settings = React.lazy(() =>
  import(
    /* webpackChunkName: "page-settings" */
    /* webpackMode: "lazy" */
    "./pages/settings/settings"
  ),
);
const Categories = React.lazy(() =>
  import(
    /* webpackChunkName: "page-categories" */
    /* webpackMode: "lazy" */
    "./pages/categories/categories"
  ),
);
const EditCategory = React.lazy(() =>
  import(
    /* webpackChunkName: "page-editcategory" */
    /* webpackMode: "lazy" */
    "./pages/categoryedit/editcategory"
  ),
);
const EditPost = React.lazy(() =>
  import(
    /* webpackChunkName: "page-editpost" */
    /* webpackMode: "lazy" */
    "./pages/postEdit/editpost"
  ),
);
const Posts = React.lazy(() =>
  import(
    /* webpackChunkName: "page-posts" */
    /* webpackMode: "lazy" */
    "./pages/posts/posts"
  ),
);
const Forms = React.lazy(() =>
  import(
    /* webpackChunkName: "page-forms" */
    /* webpackMode: "lazy" */
    "./pages/forms"
  ),
);
const Integration = React.lazy(() =>
  import(
    /* webpackChunkName: "page-integration" */
    /* webpackMode: "lazy" */
    "./pages/dashboard/integration/integration"
  ),
);
const GrigoraPreview = React.lazy(() =>
  import(
    /* webpackChunkName: "page-grigora-preview" */
    /* webpackMode: "lazy" */
    "./components/post-preview"
  ),
);

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/site/:projectID"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<DashboardSkeleton />}>
              <Dashboard />
            </Suspense>
          </LoginRequired>
        }
      >
        <Route
          path="/site/:projectID/"
          exact
          element={
            <Suspense fallback={<UserDashboardSkeleton />}>
              <UserDashboard />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/files"
          exact
          element={
            <Suspense fallback={<FilesSkeleton />}>
              <Files />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/support"
          exact
          element={
            <Suspense fallback={<SupportSkeleton />}>
              <Support />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/teams/:page"
          exact
          element={
            <Suspense fallback={<TeamsSkeleton />}>
              <Teams />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/templates"
          exact
          element={
            <Suspense fallback={<TemplateSkeleton />}>
              <Templates />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/todo"
          exact
          element={
            <Suspense fallback={<TodoSkeleton />}>
              <Todo />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/settings/:setting"
          exact
          element={
            <Suspense fallback={<SettingsSkeleton />}>
              <Settings />
            </Suspense>
          }
        />
        <Route path="/site/:projectID/editor" element={<Home />} />
        <Route
          path="/site/:projectID/cms/category"
          exact
          element={
            <Suspense fallback={<CategorySkeleton />}>
              <Categories />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/cms/addcategory"
          exact
          element={
            <Suspense fallback={<AddCategorySkeleton />}>
              <EditCategory newCategory={true} />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/cms/editcategory/:categoryID"
          exact
          element={
            <Suspense fallback={<AddCategorySkeleton />}>
              <EditCategory />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/cms/posts"
          exact
          element={
            <Suspense fallback={<PostsSkeleton />}>
              <Posts />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/forms"
          exact
          element={
            <Suspense fallback={<PostsSkeleton />}>
              <Forms />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/cms/addpost"
          exact
          element={
            <Suspense fallback={<AddEditPostSkeleton />}>
              <EditPost newPost={true} />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/cms/editpost/:postID"
          exact
          element={
            <Suspense fallback={<AddEditPostSkeleton />}>
              <EditPost newPost={false} />
            </Suspense>
          }
        />
        <Route
          path="/site/:projectID/cms/preview/:postID"
          exact
          element={
            <Suspense fallback={<GrigoraPreviewSkeleton />}>
              <GrigoraPreview />
            </Suspense>
          }
        />
        {/* <Route
          path="/site/:projectID/integration"
          exact
          element={
            <Suspense fallback={<IntegrationSkeleton />}>
              <Integration />
            </Suspense>
          }
        /> */}
      </Route>
      <Route
        path="/verify/:id"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<></>}>
              <Verify />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="/new"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<NewProjectSkeleton />}>
              <NewProject />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="/start"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <StartNewProject />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="/"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<ProjectsSkeleton />}>
              <Projects />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="/profile"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<ProfileSkeleton />}>
              <Profile />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="billing"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<></>}>
              <Billing />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="saaszilla-redeem"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<></>}>
              <SaaszillaRedeem />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="login"
        element={
          <LoginRequired not redirect={"/"}>
            <Suspense fallback={<AuthSkeleton />}>
              <Login />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="/"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<></>}>
              <Projects />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="login"
        element={
          <LoginRequired not redirect={"/"}>
            <Suspense fallback={<></>}>
              <Login />
            </Suspense>
          </LoginRequired>
        }
      />

      <Route
        path="forgot"
        element={
          <LoginRequired not redirect={"/"}>
            <Suspense fallback={<AuthSkeleton screen="forgot" />}>
              <ForgotPassword />
            </Suspense>
          </LoginRequired>
        }
      />

      <Route
        path="oauth"
        element={
          <LoginRequired not redirect={"/"}>
            <Suspense fallback={<></>}>
              <Oauth />
            </Suspense>
          </LoginRequired>
        }
      />

      <Route
        path="sign-up"
        element={
          <LoginRequired not redirect={"/"}>
            <Suspense fallback={<AuthSkeleton />}>
              <Register />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="logout"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<></>}>
              <Logout />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="/verify_collaborator_invitation/:id"
        element={
          <Suspense fallback={<></>}>
            <VerifyCollaboratorInvitation />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<NotFoundSkeleton />}>
            <NotFound404 />
          </Suspense>
        }
      />
    </>,
  ),
);

//Zoho Sales Iq Script:
const UseScript = () => {
  useEffect(() => {
    if (
      !process.env.REACT_APP_NODE_ENV ||
      process.env.REACT_APP_NODE_ENV === "development"
    ) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.innerHTML = `window.$crisp=[];window.CRISP_WEBSITE_ID="9cfaa694-0812-4c69-a51c-1afcab0ff9f5";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;

    document.body.appendChild(script);

    return () => {
      if (
        !process.env.REACT_APP_NODE_ENV ||
        process.env.REACT_APP_NODE_ENV === "development"
      ) {
        return;
      }

      document.body.removeChild(script);
    };
  }, []);
};

function App() {
  const FadeUp = cssTransition({
    enter: "toast-animated toast-fadeInLeft",
    exit: "toast-animated toast-fadeOutLeft",
    collapse: false,
    duration: 300,
  });

  return (
    <>
      <UseScript />
      <HelmetProvider>
        <GrigoraVersioning>
          <QueryClientProvider client={queryClient}>
            <Account>
              <ToastContainer
                limit={3}
                transition={FadeUp}
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ zIndex: 99999999 }}
                theme="light"
                toastClassName={"g-toastify"}
              />
              {/* <AnimatePresence> */}
              <RouterProvider router={router} />
              {/* </AnimatePresence> */}
            </Account>
          </QueryClientProvider>
        </GrigoraVersioning>
      </HelmetProvider>
    </>
  );
}

export default App;
