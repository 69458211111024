import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import { motion } from "framer-motion";
function DashboardSkeleton() {
  return (
    <SkeletonTheme>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex w-full"
      >
        <div className="mt-2 md:flex hidden flex-col items-center">
          <div className="w-full px-4 my-4">
            <Skeleton height={40} />
          </div>
          {/* sidemenu */}
          <div className="list-none mt-4 h-full w-72 px-4 overflow-y-hidden">
            <div className="flex items-center">
              <Skeleton
                className="my-4 mx-2"
                width={40}
                height={40}
                inline={true}
                circle={true}
              />
              <div className="w-full">
                <Skeleton height={40} />
              </div>
            </div>
            <div className="flex items-center">
              <Skeleton
                className="my-4 mx-2"
                width={40}
                height={40}
                inline={true}
                circle={true}
              />
              <div className="w-full">
                <Skeleton height={40} />
              </div>
            </div>
            <div className="flex items-center">
              <Skeleton
                className="my-4 mx-2"
                width={40}
                height={40}
                inline={true}
                circle={true}
              />
              <div className="w-full">
                <Skeleton height={40} />
              </div>
            </div>
            <div className="flex items-center">
              <Skeleton
                className="my-4 mx-2"
                width={40}
                height={40}
                inline={true}
                circle={true}
              />
              <div className="w-full">
                <Skeleton height={40} />
              </div>
            </div>
            <div className="flex items-center">
              <Skeleton
                className="my-4 mx-2"
                width={40}
                height={40}
                inline={true}
                circle={true}
              />
              <div className="w-full">
                <Skeleton height={40} />
              </div>
            </div>
          </div>
          {/* profile */}
          <div className="mt-4 w-full mb-2 bottom-0">
            <div className="w-[60%]">
              <Skeleton height={10} />
            </div>
            <div>
              <Skeleton height={10} />
            </div>
            <div className="flex w-full mt-4 items-center gap-4">
              <div className="w-full">
                <Skeleton width={30} height={30} circle={true} />
              </div>
              <div className="w-full">
                <Skeleton width={30} height={30} circle={true} />
              </div>
              <div className="w-full">
                <Skeleton width={30} height={30} circle={true} />
              </div>
            </div>
          </div>
        </div>

        {/* dashboard */}
        <div className="w-full">
          {/* header */}
          <div className="flex w-full items-center justify-between px-4">
            <div className="flex gap-2 w-full">
              <div className=" w-3/6">
                <Skeleton borderRadius={"21px"} height={40} />
              </div>

              <div className="w-5/6">
                <Skeleton borderRadius={"21px"} height={40} />
              </div>
            </div>
            <div className="flex items-center">
              <Skeleton
                width={40}
                className="mx-4 mt-4"
                height={40}
                circle={true}
              />
              <Skeleton
                width={40}
                className="mx-4 mt-4"
                height={40}
                circle={true}
              />
              <Skeleton
                width={40}
                className="mx-4 mt-4"
                height={40}
                circle={true}
              />
            </div>
          </div>
          {/* main dashboard container*/}
          <div className="w-full md:px-0 px-3 mt-4 flex justify-between">
            <div className="flex flex-col w-full justify-between ">
              <div className="flex md:flex-row flex-col w-full gap-4 md:pl-3 pl-0 justify-between">
                <div className="w-full">
                  <Skeleton borderRadius={"21px"} height={140} />
                </div>
                <div className="w-full">
                  <Skeleton borderRadius={"21px"} height={140} />
                </div>

                <div className="w-full">
                  <Skeleton borderRadius={"21px"} height={140} />
                </div>
              </div>
              <div className="p-4">
                <Skeleton borderRadius={"21px"} height={400} />
              </div>
            </div>
            {/* Todo section */}
            <div className="w-full w-96 md:flex hidden flex-col justify-around items-center">
              <div>
                <div className="flex w-full items-center">
                  <Skeleton
                    width={40}
                    className="my-4 mx-2"
                    height={40}
                    circle={true}
                  />
                  <div className="w-48">
                    <Skeleton height={40} />
                  </div>
                </div>
                <div className="flex items-center">
                  <Skeleton
                    width={40}
                    className="my-4  mx-2"
                    height={40}
                    circle={true}
                  />
                  <div className="w-full">
                    <Skeleton height={40} />
                  </div>
                </div>
                <div className="flex items-center ">
                  <Skeleton
                    width={40}
                    className="my-4  mx-2"
                    height={40}
                    circle={true}
                  />
                  <div className="w-full">
                    <Skeleton height={40} />
                  </div>
                </div>
              </div>
              <div className="w-full px-4">
                <Skeleton height={150} borderRadius={"21px"} />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </SkeletonTheme>
  );
}

export { DashboardSkeleton };
