import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function GrigoraPreviewSkeleton() {
  return (
    <SkeletonTheme>
      <div className="w-full h-full p-4">
        <Skeleton className="w-full h-full" borderRadius={21} />
      </div>
    </SkeletonTheme>
  );
}

export default GrigoraPreviewSkeleton;
