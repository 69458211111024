import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function DashboardNavigationSkeleton({ hasSearchBar = false }) {
  return (
    <SkeletonTheme>
      <div className="w-full flex gap-2 items-center justify-between md:px-6 px-4 md:mb-4 mb-1">
        <div className="flex items-center gap-5 md:w-2/5 w-full">
          <Skeleton borderRadius={50} width={100} height={30} />
          {hasSearchBar && (
            <div className="w-full">
              <Skeleton borderRadius={50} className="w-full" height={30} />
            </div>
          )}
        </div>
        <div className="flex items-center gap-5 ">
          <Skeleton borderRadius={50} width={20} height={20} />
          <Skeleton borderRadius={50} width={20} height={20} />
          <Skeleton borderRadius={50} width={50} height={50} />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default DashboardNavigationSkeleton;
