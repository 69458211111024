import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DashboardNavigationSkeleton from "./dashboard-navigation-skeleton";

function PostsSkeleton() {
  return (
    <SkeletonTheme>
      <div className="p-4 w-full h-3/5 md:h-4/5">
        <DashboardNavigationSkeleton hasSearchBar={true} />
        <div className="flex md:flex-row flex-col justify-between w-full items-center gap-2">
          <Skeleton borderRadius={21} width={160} height={50} />
          <div className="flex md:flex-row flex-col items-center gap-2">
            <Skeleton borderRadius={21} width={160} height={50} />
            <Skeleton borderRadius={21} width={160} height={50} />
          </div>
        </div>
        <Skeleton
          borderRadius={21}
          className="mx-auto h-full mt-3 w-full h-full"
        />
      </div>
    </SkeletonTheme>
  );
}

export default PostsSkeleton;
