import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DashboardNavigationSkeleton from "./dashboard-navigation-skeleton";

function TemplateSkeleton() {
  return (
    <SkeletonTheme>
      <DashboardNavigationSkeleton />
      <div className="w-full md:px-8 px-4">
        <div className="w-full rounded-[21px] flex md:flex-row flex-col">
          <div className="w-full md:h-[400px] h-[250px] md:w-7/12">
            <Skeleton borderRadius={21} className="w-full h-full" />
          </div>
          <div className="px-4 flex-1 w-full relative">
            <Skeleton
              borderRadius={21}
              className="my-2"
              width={200}
              height={30}
            />
            <Skeleton
              borderRadius={21}
              className="my-2"
              width={100}
              height={10}
            />
            <div className="flex justify-between items-center absolute md:bottom-0 md:right-0 w-full p-3 ">
              <Skeleton borderRadius={21} width={100} height={10} />
              <Skeleton borderRadius={21} width={130} height={50} />
              <Skeleton borderRadius={21} width={130} height={50} />
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default TemplateSkeleton;
