import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NavigationSkeleton from "./navigation-skeleton";

function ProjectsSkeleton() {
  return (
    <SkeletonTheme>
      <NavigationSkeleton />
      <div className="flex justify-between px-20 mt-16">
        <Skeleton borderRadius={50} width={100} height={10} />
        <Skeleton borderRadius={50} width={120} height={40} />
      </div>
    </SkeletonTheme>
  );
}

export default ProjectsSkeleton;
