import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function FilesSkeleton() {
  return (
    <SkeletonTheme>
      <div className="w-full h-full  flex items-center justify-center">
        <div className="flex justify-center items-center max-w-[450px] flex-wrap gap-3 bg-white px-0 p-3 rounded-[21px]">
          <Skeleton borderRadius={21} width={200} height={200} />
          <Skeleton borderRadius={21} width={200} height={200} />
          <Skeleton borderRadius={21} width={200} height={200} />
          <Skeleton borderRadius={21} width={200} height={200} />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default FilesSkeleton;
