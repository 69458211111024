import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DashboardNavigationSkeleton from "./dashboard-navigation-skeleton";

function TodoSkeleton() {
  return (
    <SkeletonTheme>
      <div className="p-4 w-full">
        <DashboardNavigationSkeleton />
        <div className="flex justify-between w-full items-center gap-2">
          <div className="w-full md:w-48">
            <Skeleton borderRadius={21} className="w-full" height={50} />
          </div>
          <div className="flex gap-4 ">
            <Skeleton borderRadius={21} width={70} height={30} />
            <Skeleton borderRadius={21} width={60} height={30} />
            <Skeleton borderRadius={21} width={60} height={30} />
          </div>
        </div>
        <Skeleton
          borderRadius={21}
          className="mx-auto h-full mt-3 w-full"
          height={400}
        />
      </div>
    </SkeletonTheme>
  );
}

export default TodoSkeleton;
