import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import classnames from "classnames";
import "./modal.scss";
function ModalPortal({
  children,
  onOutsideClick,
  onInsideClick,
  overlayClassName,
  overlayFrameClassName,
  backgroundColor = "rgba(0, 0, 0, 0.5)",
}) {
  const classes = classnames(
    "editor-dashboard-modal-ui-kit fixed inset-0 flex justify-center items-center",
    overlayClassName,
  );

  const frameClasses = classnames(
    "editor-dashboard-modal-ui-kit__frame w-fit rounded-[21px] bg-editor-light-main flex justify-start items-center",
    overlayFrameClassName,
  );

  const modalRef = useRef();

  useEffect(() => {
    modalRef?.current?.focus();
  }, [modalRef]);

  return createPortal(
    <>
      <motion.div
        ref={modalRef}
        tabIndex={-1}
        key="modal"
        initial={{
          opacity: 0,
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        animate={{
          opacity: 1,
          backgroundColor: backgroundColor,
          transition: {
            ease: "easeOut",
            duration: 0.15,
          },
        }}
        exit={{
          opacity: 0,
          backgroundColor: "rgba(0, 0, 0, 0)",
          transition: {
            ease: "easeIn",
            duration: 0.15,
          },
        }}
        onMouseDown={onOutsideClick}
        className={classes}
        onKeyDown={(e) => {
          // If Esc Key Press
          if (e.keyCode === 27) {
            onOutsideClick(false);
          }
        }}
      >
        <div
          className={frameClasses}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (onInsideClick) {
              onInsideClick();
            }
          }}
        >
          <div className="mx-auto">{children}</div>
        </div>
      </motion.div>
    </>,
    document.body,
  );
}

export default function ModalInput({
  children,
  isOpened,
  onInsideClick,
  onOutsideClick,
  overlayClassName,
  overlayFrameClassName,
  backgroundColor,
}) {
  return (
    <>
      <AnimatePresence>
        {isOpened && (
          <ModalPortal
            onInsideClick={onInsideClick}
            onOutsideClick={onOutsideClick}
            overlayClassName={overlayClassName}
            overlayFrameClassName={overlayFrameClassName}
            backgroundColor={backgroundColor}
          >
            {children}
          </ModalPortal>
        )}
      </AnimatePresence>
    </>
  );
}
