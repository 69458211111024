import { useCallback, useEffect, useRef } from "react";

const useRefCallback = (value, deps) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, deps ?? [value]);

  const result = useCallback((...args) => {
    ref.current?.(...args);
  }, []);

  return result;
};

export { useRefCallback };
