import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function NavigationSkeleton() {
  return (
    <SkeletonTheme>
      <div className="md:w-full flex justify-between items-center md:p-3 px-3 md:px-[130px] ">
        <Skeleton borderRadius={50} height={40} width={40} />
        <div className="flex md:gap-10 gap-4">
          <Skeleton borderRadius={50} height={10} width={80} />
          <Skeleton borderRadius={50} height={10} width={80} />
          <Skeleton borderRadius={50} height={10} width={80} />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default NavigationSkeleton;
